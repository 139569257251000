/* ********************************** START RECIEPT LAYOUT*************************************** */

.reciept-img-header {
    width: 200px;
    height: auto;
    margin-bottom: 40px;
}

.reciept-hide{
    display: none;
}

.reciept-layout{
    padding: 3rem 3rem;
}

.reciept-layout > h6{
    line-height: 1.2;
}

.reciept-owner{
    font-weight: 600;
}

.reciept-id{
    font-weight: 600;
    font-size: 16px;
    margin: 2rem 0;
}

.content-subCategory > h6 {
    font-weight: 600;
}

.reciept-lh > h6{
    line-height: 1.05;
}

/* ********************************** END RECIEPT LAYOUT*************************************** */