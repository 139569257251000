@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Playfair+Display:wght@400;500;600&family=Libre Franklin:wght@100;300;400;500;700;900&display=swap');
/*
 * PRINT LAYOUT
 *--------------------*/
/* show print layout for wines and article  */


.print-font-small {
    font-size: 14px !important;
    margin-bottom: 20px;
    font-weight: 100;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
}

.print-font-mid{
    font-size: 18px !important;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
}

.print-font-mid-double{
    font-size: 18px !important;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
}

.print-img-header {
    width: 200px;
    height: auto;
    margin-bottom: 40px;
}

.form-group label{
    font-size: 18px;
    font-weight: 300;
}

.right-side-list strong{
    font-size: 18px;
}

.content-container-single{
    display: flex;
    flex-direction: column;
}
.row-container {
    display: flex;
    justify-content: space-between;
    width: auto;
}

/* hide print layout for wines and article */
.print-hide {
    display: none !important
}

.print-title {
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}

.print-title.print-title-1x{
    font-size: 22px;
}

/* For 2x2 and 3x1 print layout */
.flex-row {
    display: flex;
    flex-wrap: wrap;
}

.flex-row .flex-item {
    display: flex;
    margin-bottom: 20px;
}

.flex-row .flex-item--inner-row {
    padding: 0 7.5px;
}

.flex-row .flex-item .border {
    border: 1px solid #e1e1e1;
}

.flex-row .flex-item>a {
    width: 100%;
}

.print-layout {
    background-color: none;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.wines-view-compare .tasting-notes .tasting-note,
.print-layout .tasting-notes .tasting-note {
    padding: 20px 15px;
    background-color: #e1e1e1;
    margin-bottom: 20px;
}

.wines-view-compare .tasting-notes .tasting-note.selection-box,
.print-layout .tasting-notes .tasting-note.selection-box {
    padding: 30px;
}

.wines-view-compare .tasting-notes .tasting-note .print-content,
.print-layout .tasting-notes .tasting-note .print-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
}

.wines-view-compare .tasting-notes .tasting-note .print-content>*,
.print-layout .tasting-notes .tasting-note .print-content>* {
    flex: 1;
}

.wines-view-compare .tasting-notes .tasting-note .print-content .print-header,
.print-layout .tasting-notes .tasting-note .print-content .print-header {
    flex: 0;
}

.wines-view-compare .tasting-notes .tasting-note .print-content .tastingnote-container,
.print-layout .tasting-notes .tasting-note .print-content .tastingnote-container {
    align-items: flex-start;
    flex: 2;
}

.wines-view-compare .tasting-notes .tasting-note .print-content .print-footer,
.print-layout .tasting-notes .tasting-note .print-content .print-footer {
    flex: 0 0 60px;
}

.print-content.content-long{
    height: 94vh !important;
}

.footer-cr{
    font-size: 11px !important;
    font-weight: 500;
    font-family: 'Libre Franklin', sans-serif;
}

.footer-cr-3x{
    font-size: 11px !important;
    font-weight: 300;
    font-family: 'Libre Franklin', sans-serif;
}

.wines-view-compare .tasting-notes .page-break,
.print-layout .tasting-notes .page-break {
    border: 2px solid red;
    clear: both;
    page-break-after: always;
    -moz-column-break-after: always;
    break-after: always;
}

.wines-view-compare hr.no-top-margin,
.print-layout hr.no-top-margin {
    margin-top: 0;
}

.wines-view-compare .x22.tasting-notes .tasting-note,
.wines-view-compare .x31.tasting-notes .tasting-note,
.print-layout .x22.tasting-notes .tasting-note,
.print-layout .x31.tasting-notes .tasting-note {
    page-break-after: avoid;
    background-color: transparent;
    margin-bottom: 0;
}

.print-layout .x22.tasting-notes .tasting-note.double-print{
    page-break-after: avoid;
    background-color: transparent;
    margin-bottom: 0;
    min-height: 50vh;
    max-height: 50vh;
}

.wines-view-compare .x22.tasting-notes .tasting-note .print-logo,
.wines-view-compare .x31.tasting-notes .tasting-note .print-logo,
.print-layout .x22.tasting-notes .tasting-note .print-logo,
.print-layout .x31.tasting-notes .tasting-note .print-logo {
    margin-left: -5px;
}

.wines-view-compare .x22.tasting-notes .tasting-note .print-footer p,
.wines-view-compare .x31.tasting-notes .tasting-note .print-footer p,
.print-layout .x22.tasting-notes .tasting-note .print-footer p,
.print-layout .x31.tasting-notes .tasting-note .print-footer p {
    margin-bottom: 0;
    line-height: 120%;
}

.wines-view-compare .x22.tasting-notes .tasting-note .print-footer p.author-name,
.wines-view-compare .x31.tasting-notes .tasting-note .print-footer p.author-name,
.print-layout .x22.tasting-notes .tasting-note .print-footer p.author-name,
.print-layout .x31.tasting-notes .tasting-note .print-footer p.author-name {
    padding-top: 10px;
    margin-bottom: 10px;
}

.wines-view-compare .x22.tasting-notes .tasting-note svg,
.wines-view-compare .x31.tasting-notes .tasting-note svg,
.print-layout .x22.tasting-notes .tasting-note svg,
.print-layout .x31.tasting-notes .tasting-note svg {
    width: 208px;
    height: 108px;
}

.wines-view-compare .x22.tasting-notes .tasting-note svg path,
.wines-view-compare .x31.tasting-notes .tasting-note svg path,
.print-layout .x22.tasting-notes .tasting-note svg path,
.print-layout .x31.tasting-notes .tasting-note svg path {
    fill: #2c181e;
}

.wines-view-compare .x22 .box,
.wines-view-compare .x31 .box,
.print-layout .x22 .box,
.print-layout .x31 .box {
    margin-top: 100%;
}

.wines-view-compare .x22 .thumbnail,
.wines-view-compare .x31 .thumbnail,
.print-layout .x22 .thumbnail,
.print-layout .x31 .thumbnail {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin-bottom:0;
    border-radius: 0;
    border: solid 1px #0f0f0f;
    max-height: 96vh;
    min-height: 96vh;
}

.border-title {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin-bottom:100px;
    border-radius: 0;
    border: solid 1px #0f0f0f;
    max-height: auto;
    min-height: auto;
}

.print-layout .x22 .thumbnail.double-thumbnail{
    max-height: 46.5vh;
    min-height: 46.5vh;
    height: 100%;
}

.print-layout .x22 .thumbnail.double-thumbnail {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin-bottom:0;
    border-radius: 0;
    border: solid 1px #0f0f0f;
}

.wines-view-compare .x22 p,
.wines-view-compare .x31 p,
.print-layout .x22 p,
.print-layout .x31 p {
    font-size: 17px;
}

.wines-view-compare .x22 p.fontRating,
.wines-view-compare .x31 p.fontRating,
.print-layout .x22 p.fontRating,
.print-layout .x31 p.fontRating {
    font-size: 15px;
}

.wines-view-compare .x22 p.lead,
.wines-view-compare .x31 p.lead,
.print-layout .x22 p.lead,
.print-layout .x31 p.lead {
    margin-bottom: 5px;
}

.wines-view-compare .x22 h3,
.wines-view-compare .x22 .h3,
.wines-view-compare .x31 h3,
.wines-view-compare .x31 .h3,
.print-layout .x22 h3,
.print-layout .x22 .h3,
.print-layout .x31 h3,
.print-layout .x31 .h3 {
    font-size: 30px;
    font-weight: 500;
}

.wines-view-compare .x22 h3.wineRating,
.wines-view-compare .x22 .h3.wineRating,
.wines-view-compare .x31 h3.wineRating,
.wines-view-compare .x31 .h3.wineRating,
.print-layout .x22 h3.wineRating,
.print-layout .x22 .h3.wineRating,
.print-layout .x31 h3.wineRating,
.print-layout .x31 .h3.wineRating {
    display: inline-block;
    margin: 10px 0 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    color: #b08883;
    border-bottom: solid 5px #0f0f0f;
}

.wines-view-compare .x22 label,
.wines-view-compare .x31 label,
.print-layout .x22 label,
.print-layout .x31 label {
    font-size: 10px;
}

.wines-view-compare .x22 hr,
.wines-view-compare .x31 hr,
.print-layout .x22 hr,
.print-layout .x31 hr {
    margin-bottom: 5px;
    border-top-color: #0f0f0f;
}

.wines-view-compare .x22 .fixed-width,
.print-layout .x22 .fixed-width {
    width: 340px;
}

.wines-view-compare .x22 .content-tastingnote,
.print-layout .x22 .content-tastingnote {
    font-size: 14px !important;
    font-weight: 300;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
    overflow: hidden;
}

.wines-view-compare .x22 .print-content.truncate,
.print-layout .x22 .print-content.truncate {
    position: relative;
}


.wines-view-compare .x31 .fixed-width,
.print-layout .x31 .fixed-width {
    width: 230px;
}

.wines-view-compare .x31 h3.wineRating,
.wines-view-compare .x31 .h3.wineRating,
.print-layout .x31 h3.wineRating,
.print-layout .x31 .h3.wineRating {
    margin-bottom: 15px;
}

.wines-view-compare .x31 hr,
.print-layout .x31 hr {
    margin: 15px 0;
}

.wines-view-compare .print-logo,
.print-layout .print-logo {
    max-width: 100%;
}

.wines-view-compare .shopping-list,
.print-layout .shopping-list {
    margin-top: 50px;
}

.wines-view-compare .table>thead>tr>th,
.wines-view-compare .table>tbody>tr>th,
.wines-view-compare .table>tfoot>tr>th,
.wines-view-compare .table>thead>tr>td,
.wines-view-compare .table>tbody>tr>td,
.wines-view-compare .table>tfoot>tr>td,
.print-layout .table>thead>tr>th,
.print-layout .table>tbody>tr>th,
.print-layout .table>tfoot>tr>th,
.print-layout .table>thead>tr>td,
.print-layout .table>tbody>tr>td,
.print-layout .table>tfoot>tr>td {
    padding: 20px;
}

.wines-view-compare .no-selected-wine,
.print-layout .no-selected-wine {
    background: #e1e1e1;
    display: block;
    margin-bottom: 20px;
    padding: 20px;
}

.print-title-double{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;
}

.print-tile-image{
    width: 200px;
    height: 100%;
}

.print-title-2x{
    font-size: 20px !important;
    text-align: start;
    margin-bottom: 0;
    padding: 0;
    font-weight: 800;
    font-family: 'Playfair Display', serif;
}

.print-title-3x{
    font-size:  20px !important;
    text-align: center;
    font-weight: 800;
    margin-bottom: 0;
    padding: 0;
    font-family: 'Playfair Display', serif;
}

.print-double-line-header{
    margin: 0.4rem 0 0.6rem 0;
}

.p-tastingnote{
    font-size: 15px !important;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
}

.p-tastingnote-3x1{
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Libre Franklin', sans-serif;
}
.p-tastingnote-double{
    font-weight: 400;
}

.tasting-note-2-content{
    padding-top: 50px;
}

@media print {
    @page {
        size: A4;
        margin: 0;
    }
    .page {
        page-break-after: always;
        page-break-inside: avoid;
    }

    html,
    body {
        width: 210mm;
        height: 282mm;
        overflow: visible;
    }

    .print-layout {
        width: 100%;
    }

    .print-layout.safari .tasting-notes.x22 .tasting-note {
        height: calc(84.5mm);
    }

    .print-layout.safari .tasting-notes.x22 .tasting-note:nth-child(4n+4) {
        page-break-after: always;
        -moz-column-break-after: always;
        break-after: always;
    }

    .print-layout.safari .tasting-notes.x31 .tasting-note {
        height: 297mm;
    }

    .print-layout .x22,
    .print-layout .x31 {
        width: 100%;
    }

    .print-layout .x22 .row,
    .print-layout .x31 .row {
        margin: 0;
    }

    .triple-thumbnail{
        height: 100%;
    }

    .print-layout .x22 .tasting-note .thumbnail.single-thumbnail{
        height: 48%;
    }

    .print-layout .x22 .tasting-note .print-content,
    .print-layout .x31 .tasting-note .thumbnail,
    .print-layout .x31 .tasting-note .print-content {
        height: 100%;
    }

    .print-layout .x22 .tasting-note .thumbnail,
    .print-layout .x31 .tasting-note .thumbnail {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        padding: 10px;
        margin: 0;
    }

    .print-layout .x22 .tasting-note h3.wineRating,
    .print-layout .x31 .tasting-note h3.wineRating {
        color: rgba(176, 136, 131, 1) !important;
    }

    .print-layout .x31 .tasting-note {
        min-height: 100vh;
    }

    .print-layout .x22 .tasting-note {
        width: 50%;
        min-height: 100vh   ;
        overflow: hidden;
    }

    .print-layout .print-friendly {
        padding: 60px 50px;
    }

    .print-layout .print-friendly .tasting-note {
        -moz-column-break-inside: avoid;
        break-inside: avoid;
    }

    .print-friendly-search{
        height: 100vh;
    }

    @-moz-document url-prefix() {
        .print-layout .x22 .tasting-note {
            height: calc(148.5mm);
        }

        .print-layout .x31 .tasting-note {
            height: 297mm;
        }
    }
}

.border-container-short {
    padding-top: 20px;
    text-align: center;
    border-radius: 0;
    border: solid 2px #0f0f0f;
    height: 650px !important;
}

.padding-footer {
    padding-bottom: 20px;
}


/* ********************************** END PRINT LAYOUT*************************************** */


/* ********************************** START SHOPPING LIST LAYOUT*************************************** */

.print-img-header-shopping{
    width: 150px;
    margin-bottom: 10px;
    height: auto;
}

.shopping-list-header{
    padding: 1.1rem;
}

.shopping-list-header > div{
    font-weight: bold;
}

.shopping-list-header > div.wine-name{
    padding-left: 2rem;
}

.shopping-list-main-separator{
    margin: 0;
}

.shopping-content{
    padding: 1.1rem;
    border-top: 1px solid #ccc;
}

.shopping-content > div.wine-name{
    line-height: 1.2;
    padding-left: 2rem;
}

.shopping-footer{
    width: 100%;
    margin-top: 1rem;
    font-size: 12px;
    text-align: center;
}

/* ********************************** END SHOPPING LIST LAYOUT*************************************** */
