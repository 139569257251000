.modal-transition-enter {
  opacity: 0;
  transform: translateY(-100%) translateX(-50%) !important;
}

.modal-transition-enter-active {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%) !important;
  transition: opacity 300ms, transform 400ms;
}

.modal-transition-exit {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%) !important;
}

.modal-transition-exit-active {
  opacity: 0;
  transform: translateY(-100%) translateX(-50%) !important;
  transition: opacity 300ms, transform 400ms;
}
    
  .modal-transition {
    position: fixed;
    /* top: 15%;
    left: 35%; */
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    width: 100%;
    height: 100%;
}
  
  .backdrop-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
  

  @media screen and (max-width: 600px) {
    .modal-transition {
      max-width: 100%;
      width: 100%;
    }
  }

  