.select-button{
    display: flex;
    gap: 0.3rem;
    align-items: center;
    outline: none;
    border: solid 1px #ccc;
    border-radius: 8px;
    padding: 0.4rem;
    background-color: white;
    color: black;
}

.select-icon{
    color: '#777777';
}

.select-button:disabled{
    background-color: #E5E5E5;
    color: white;
    border: 1px solid white;
}