/* ********************************** START PDF RECIEPT LAYOUT*************************************** */
.reciept-layout{
    margin: 20px 30px 0 30px;
}

.receipt-logo {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;            
    height: 100px;          
}

.separator {
    height: 1px;
    background-color: #979797; 
    width: 100%;
    margin: 18px 0 0 0; 
}

.light-separator {
    height: 1px;
    background-color: #ddd; 
    width: 100%;
    margin: 10px 0 17px 0; 
}

.theme-separator {
    height: 1px;
    background-color: #a18436; 
    width: 100%;
    margin: 18px 0 0 0; 
}

.text-style {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #333;
}

.intro {
    margin-top: 20px;
}

.outro {
    margin-top: 20px;
    font-style: italic;
    font-size: 12px;
}

.header {
    font-family: Libre Franklin;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    margin: 30px 0 0 0;
}

.payment-receipt {
    display: flex;
}

.payment-content {
    width: 50%;
}

.payment-receipt .field.text-bold {
    font-weight: bold;
    margin-top: 24px;
}

.tabbed {
    display: inline-block;
    padding-left: 2em; 
}

.field {
    font-family: Libre Franklin;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #333;
    margin-top: 4px;
}

.breakdown {
    display:flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
}

.tax-info {
    text-align: end;
    font-size: 12px;
    font-weight: 500;
}

.total {
    text-align: end;
    margin-top: 18px;
    font-weight: 600;
}

.centered-info {
    text-align: center;
    line-height: 1.5;
    margin-top: 40px;
}

.text-bold {
    font-weight: 600;
}

.margin-top-30px {
    margin-top: 30px;
}

.margin-top-20px {
    margin-top: 17px;
}

.margin-top-15px {
    margin-top: 15px;
}

.margin-top-6px {
    margin-top: 6px;
}

/* ********************************** END PDF RECIEPT LAYOUT*************************************** */