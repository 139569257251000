

.sub-history{
    width: 80%;
    border: solid 1px #a18436;
    border-radius: 12px;
    margin-top: 16px;
    padding: 24px;
}

@media(max-width:1000px){

    .subscription-container{
        margin-top: 2rem;
    }

    .subscription-container > div{
        width: 100%;
        border: solid 1px #a18436;
        border-radius: 12px;
        margin-top: 16px;
        padding: 24px;
    }

    .sub-history{
        width: 100%;
    }

}

.sub-selection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-design {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
}