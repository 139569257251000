/* Loading screen - Mawie */
.overlay {
    position: fixed;
    /* Sit on top of the page content */
  
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 9999;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
  }

  .text-mobile {
    font-size: 20px;
  }
  
  .load {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  
  .load-3 {
    padding-left: 80px;
    padding-bottom: 20px;
  }

  .load-mobile {
    padding-left: 0 !important;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .load-wrapp {
    float: left;
    width: 100px;
    height: 100px;
    /* margin: 0 10px 10px 0; */
    padding: 20px 0px 0px;
    border-radius: 8px;
    text-align: center;
    background-color: #d8d8d8;
  }
  
  .load-wrapp p {
    padding: 0 0 20px;
  }
  
  .load-wrapp:last-child {
    margin-right: 0;
  }
  
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #fff;
    margin-right: 5px;
  }
  
  .load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
  }
  
  .load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
  }
  
  .load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
  }
  
  .load-3 .line:nth-last-child(4) {
    animation: loadingC 0.6s 0.4s linear infinite;
  }
  
  .load-3 .line:nth-last-child(5) {
    animation: loadingC 0.6s 0.5s linear infinite;
  }
  
  
  @-webkit-keyframes loadingC {
    0% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0, 15px);
    }
  
    100% {
      transform: translate(0, 0);
    }
  }
  
  @-moz-keyframes loadingC {
    0% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0, 15px);
    }
  
    100% {
      transform: translate(0, 0);
    }
  }
  
  @keyframes loadingC {
    0% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0, 15px);
    }
  
    100% {
      transform: translate(0, 0);
    }
  }