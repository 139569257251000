/* Drawer */

.open-drawer-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
}

.drawer {
    position: fixed;
    top: 0;
    right: -350px;
    width: 350px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out;
    z-index: 21;
}

.drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem;
    padding-right: 1rem;;
}

.drawer-body {
    overflow-y: auto;
    margin-bottom: 3rem;
}

.drawer-header > span {
    font-weight: bold;
    font-size: 16px;
}

.drawer-header > img {
    cursor: pointer;
}

.drawer.open {
    right: 0;
    overflow-y: auto;
}

.close-drawer-btn {
    margin: 10px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.drawer-bdrop {
    background-color: hsla(0, 0%, 0%, 0.09);
}
