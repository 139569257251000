.main-article-container > figure{
    margin: 1.75em 0;
}

.main-article-container > figure > img {
    margin: auto;
    width: 100%;
    font-style: italic;
}

.main-article-container > figure > figcaption {
    text-align: center;
    margin-top: 10px;
    line-height: 1.2;
    font-size: 0.75em;
}

.main-article-container > div {
    width: 100% !important;
}

.main-article-container > p {
    font-size: 1.22rem !important;
    font-family: "Libre Franklin", Helvetica, Arial, sans-serif !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.entry-cat.cat-theme.cat-5.issue-link, .entry-cat.cat-theme.cat-5.issue-link-mobile {
    text-decoration: underline;
    border-bottom: 0;
}

.entry-cat.cat-theme.cat-5.issue-link-mobile {
    margin-top: 1.5rem;
}

.article-wine-list {
    display: none;
	height: 33px;
	margin: 20px 0;
	padding: 6px 13px;
	border-radius: 8px;
	border: solid 1px #ccc;
	background-color: var(--white-three);
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	cursor: pointer;
    width: fit-content;
}

.article-wine-list > a {
    color: #333;
		text-decoration: none;
}

.mnmd-offcanvas-article {
    max-width: 100%;
	width: 320px;
	position: fixed;
	top: 0;
	left: 0;
	right: auto;
	bottom: 0;
	margin: 0;
	overflow: hidden;
	background: #fff;
	z-index: 1039;
    /* transform: translate(-101%, 0); */
    transition: all 0.2 ease 0.1s;
    width: 360px;
    display: none;
    /* visibility: hidden; */
}

.mnmd-offcanvas.isList-active {
    /* visibility: visible; */
    display: block;
    box-shadow: 0 1px 10px 10px rgba(0, 0, 0, 0.1);
    /* transform: translate(0, 0); */
}

.mnmd-sub-col.mnmd-sub-col--right.sidebar.js-sticky-sidebar > .wine-info-container {
    margin-bottom: 1rem;
}

.article-back-button {
    color: #73333f;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
}

@media(max-width: 992px) {

    .article-wine-list {
        display: flex;
        justify-self: flex-end;
    }

    .mnmd-sub-col.mnmd-sub-col--right.sidebar.js-sticky-sidebar > .wine-info-container {
        display: none;
    }
}


.mobile-article-top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-article {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width: 992px) {
    .header-article {
        padding-top: 0;
    }
}
