.sub-menu-enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.sub-menu-enter-active {
    opacity: 1;
    height: 100%;
    transition: opacity 500ms, height 500ms ease-in-out;
}

.sub-menu-exit {
    opacity: 1;
    height: 100%;
}

.sub-menu-exit-active {
    opacity: 0;
    height: 0;
    transition: opacity 500ms, height 500ms ease-in-out;
}    